import ROLES from '..//constants/roles'

/* General Permissions Logic */
export function isBillingRole({ authUser }) {
  return authUser.roles.includes(ROLES.BILLING)
}

export function isInGoodStanding({ org }) {
  return !(
    !!org.hasUnpaidSspSubscription ||
    !!org.hasUnpaidRrpSubscription ||
    !!org.hasUnpaidFocusSubscription
  )
}

/* SSP */
export function isSspCertified({ authUser }) {
  return !!authUser.productPreferences?.sspCertification?.completedAt
}

export function sspCertifiedBilling({ authUser, org }) {
  return isBillingRole({ authUser }) && !!org?.hasOrgSspCertificationCompleted
}

export function isSspPreCertification({ authUser }) {
  return (
    !!authUser.productPreferences?.sspCertification?.enrolledAt &&
    !authUser.productPreferences?.sspCertification?.completedAt
  )
}

export function hasEnrolledSspCertification({ authUser }) {
  return !!authUser.productPreferences.sspCertification?.enrolledAt
}

export function hasActiveSspSubscription({ org }) {
  return !!org.hasPaidSspSubscription || !!org.hasPastDueSspSubscription
}

export function hasSspSubscription({ org }) {
  return (
    !!org.hasPaidSspSubscription ||
    !!org.hasPastDueSspSubscription ||
    !!org.hasPendingSspSubscription
  )
}

export function anyStateSspSubscription({ org }) {
  return (
    !!org.hasPendingSspSubscription ||
    !!org.hasPaidSspSubscription ||
    !!org.hasPastDueSspSubscription ||
    !!org.hasUnpaidSspSubscription ||
    !!org.hasAllCancelledSspSubscription
  )
}

/* RRP */
export function isRrpCertified({ authUser }) {
  return !!authUser.productPreferences.rrpCertification?.completedAt
}

export function rrpCertifiedBilling({ authUser, org }) {
  return isBillingRole({ authUser }) && !!org.hasOrgRrpCertificationCompleted
}

export function isRrpPreCertification({ authUser }) {
  return (
    !!authUser.productPreferences.rrpCertification?.enrolledAt &&
    !authUser.productPreferences.rrpCertification?.completedAt
  )
}

export function hasEnrolledRrpCertification({ authUser }) {
  return !!authUser.productPreferences.rrpCertification?.enrolledAt
}

export function hasActiveRrpSubscription({ org }) {
  return !!org.hasPaidRrpSubscription || !!org.hasPastDueRrpSubscription
}

export function hasRrpSubscription({ org }) {
  return (
    !!org.hasPaidRrpSubscription ||
    !!org.hasPastDueRrpSubscription ||
    !!org.hasPendingRrpSubscription
  )
}

export function anyStateRrpSubscription({ org }) {
  return (
    !!org.hasPendingRrpSubscription ||
    !!org.hasPaidRrpSubscription ||
    !!org.hasPastDueRrpSubscription ||
    !!org.hasUnpaidRrpSubscription ||
    !!org.hasAllCancelledRrpSubscription
  )
}

/* ILS */
export function isIlsCertified({ authUser }) {
  return !!authUser.productPreferences.focusCertification?.completedAt
}

export function ilsCertifiedBilling({ authUser, org }) {
  return isBillingRole({ authUser }) && !!org.hasOrgFocusCertificationCompleted
}

export function isIlsPreCertification({ authUser }) {
  return (
    !!authUser.productPreferences.focusCertification?.enrolledAt &&
    !authUser.productPreferences.focusCertification?.completedAt
  )
}

export function hasEnrolledIlsCertification({ authUser }) {
  return !!authUser.productPreferences.focusCertification?.enrolledAt
}

export function hasActiveIlsSubscription({ org }) {
  return !!org.hasPaidFocusSubscription || !!org.hasPastDueFocusSubscription
}

export function hasIlsSubscription({ org }) {
  return (
    !!org.hasPaidFocusSubscription ||
    !!org.hasPastDueFocusSubscription ||
    !!org.hasPendingFocusSubscription
  )
}

export function anyStateIlsSubscription({ org }) {
  return (
    !!org.hasPendingFocusSubscription ||
    !!org.hasPaidFocusSubscription ||
    !!org.hasPastDueFocusSubscription ||
    !!org.hasUnpaidFocusSubscription ||
    !!org.hasAllCancelledFocusSubscription
  )
}

export function showRRPStoreFront({ org, authUser, ff }) {
  // is in good standing
  if (!isInGoodStanding({ org })) return false

  // Has a cancelled RRP subscription */
  if (org.hasAllCancelledRrpSubscription) return true

  return (
    // Is an SSP subscriber
    hasActiveSspSubscription({ org, authUser }) &&
    // has a billing role, and is a SSP certified */
    sspCertifiedBilling({ org, authUser }) &&
    // newPlan and RRP feature flags are on */
    !!ff.showNewSubscriptionPlan &&
    !!ff.showRRPProduct &&
    // Hasn't already enrolled in Certification or purchased an RRP Subscription
    !(hasEnrolledRrpCertification({ authUser }) && hasRrpSubscription({ org }))
  )
}
